/* Styles for the Admission Form */
.adm-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
.adm-form-container form {
    max-width: 400px;
    margin: 0 auto;
  }
  
.adm-form-container label {
    display: block;
    font-weight: bold;
  }
  
.adm-form-container input[type="text"],
.adm-form-container input[type="email"],

.adm-form-container select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
.adm-form-container select {
    height: 36px;
  }
  
.adm-form-container button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }
  
.adm-form-container button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .adm-form-container div {
    padding: 3px;
  }