.App {
  background-color: white;
}

.header {
  /* Header styles */
}

.slider {
  /* Slider styles */
}

.body {
  /* Body styles */
}

.footer {
  /* Footer styles */
}

@media screen and (max-width: 767px) {
  .main-display-container {
    
  }

  .header {
    /* Responsive header styles */
  }

  .slider {
    /* Responsive slider styles */
  }

  .body {
    /* Responsive body styles */
  }

  .footer {
    /* Responsive footer styles */
  }
}

@media screen and (max-width: 479px) {
  .main-display-container {
    
  }

  .header {
    /* Further responsive header styles */
  }

  .slider {
    /* Further responsive slider styles */
  }

  .body {
    /* Further responsive body styles */
  }

  .footer {
    /* Further responsive footer styles */
  }
}


.login-form-container, .login-form {
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
color: white;
background-image: linear-gradient(79deg,#7439db, #C66FBC 48%, #F7944D);
}

.register-form-container, .register-form {
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
color: white;
background-image: linear-gradient(79deg,#7439db, #C66FBC 48%, #F7944D);
}

@media screen and (min-width: 600px) {
  .login-form-container {
    padding: 4rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
  .register-form-container {
    padding: 4rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.3rem 0;
}

input {
  margin: 0.4rem 0;
  padding:0.5rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}