/*************** Header styling **********************/
.main-header-container {
    background-color: white;
    height: 89px;
}

.main-header-sub-container {
    display: flex;
    justify-content: space-between;
    margin-right: 40px;
    margin-left: 40px;
}

.header-container {
    margin-top: 10px;
    margin-bottom: 1px;
}

.company-logo {
    width: 134px;
    height: 75px;
}

  
/***********************************************************************/