.header-contact-us {
    background-color: #2b5294;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), 
                -5px -5px 10px rgba(179, 214, 23, 0.5); */
    height: 70px;
}

.header-contact-us-h1 {
    padding-top: 5px;
    margin-left: 50px;
    font-size: 40px;
    color: white;
}

.register-user-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    background: white;
    font-family: 'Roboto', sans-serif;
    width: auto;
    margin: 0 auto;
    padding: 30px 0;
    font-size: 20px;
    flex-direction: row;
    justify-content: space-around;
}

.contact-mainbox-1 {
    display: flex;
    color: black;
}

.contact-mainbox-2 {
    display: flex;
    color: black;
}

.contact-mainbox-1-sub1, .contact-mainbox-1-sub2, .contact-mainbox-2-sub1, .contact-mainbox-2-sub2 {
    box-sizing: border-box;
    width: 50%;
    height: 250px;
    text-align: center;
    align-content: center;
    align-items: center;
}

.contact-mainbox-1-sub1-phone-tag {
    font-weight: bold;
}

.contact-mainbox-1-sub2-email-tag {
    font-weight: bold;
}

.contact-mainbox-2-sub1-add-tag {
    font-weight: bold;
}

.contact-mainbox-2-sub2-wh {
    font-weight: bold;
}

.user-add, .user-add h2 {
    text-align: center;
    color: black;
}

.user-add-headr {
    color: black;
}

.contact-box {
    background-color: white;
    border: 7px solid rgb(216, 219, 218);
    padding: 20px;
    margin: 0px;
    height: auto;
    width: 1200px;
}

.user-form-container {
    position: relative;
    background-color: #f2f3f7;
    color: black;
    width: 350px;
    height: 350px;
}

.register-user-container h2 {
    display: block;
    font-size: 1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-weight: bold;
    margin-top: 25px;
    position: relative;
    text-align: center;
    color: #636363;
    font-weight: 500;
    line-height: 1.2;
}

.uname {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
}

.usubmit {
    margin-top: 25px;
}

.u-submit {
    width: auto;
    display: inline-block;
    padding: 10px 20px;
    background-image: linear-gradient(to right, red, #333);
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 580px) {
    .user-form-container {
        position: relative;
        background-color: #f2f3f7;
        color: black;
        width: auto;
        height: 350px;
    }
    .register-user-container {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1000px) {
    .contact-box {
        background-color: white;
        border: 7px solid rgb(216, 219, 218);
        padding: 20px;
        margin: 50px;
        height: auto;
        width: 800px;
    }
}

@media screen and (max-width: 800px) {
    .contact-box {
        background-color: white;
        border: 7px solid rgb(216, 219, 218);
        padding: 20px;
        margin: 50px;
        height: auto;
        width: 600px;
    }
    .header-contact-us-h1 {
        margin-left: 15px;
        font-size: 35px;
    }
    .contact-mainbox-1, .contact-mainbox-2 {
        display: flex;
        flex-direction: column;
        color: black;
        align-items: center;
        text-align: center;
        align-content: center;
    }
    .contact-mainbox-1-sub2-email1 {
        text-align: center;
        align-content: center;
        align-items: center;
    }

    .contact-mainbox-1-sub1, 
    .contact-mainbox-1-sub2, 
    .contact-mainbox-2-sub1, 
    .contact-mainbox-2-sub2 {
        box-sizing: border-box;
        width: 100%;
        height: 250px;
        text-align: center;
        align-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .contact-box {
        background-color: white;
        border: 7px solid rgb(216, 219, 218);
        padding: 20px;
        margin: 50px;
        height: auto;
        width: 300px;
    }
}

@media screen and (max-width: 400px) {
    .contact-box {
        background-color: white;
        border: 7px solid rgb(216, 219, 218);
        padding: 20px;
        margin: 50px;
        height: auto;
        width: 250px;
    }
}