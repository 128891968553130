.footer-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: black;
    color: white;
    margin-bottom: 0px;
}

.footer-links li:not(:last-child)::after {
    content: "|";
    margin: 0 5px;
  }

  .footer-links li a {
    text-decoration: none;
    color: white;
  }
#privacy-policy {
  cursor: pointer;
}

  .copyright {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: black;
    color: white;
  }

  .footer-main-content {
    position: relative;
    width: 100%;
    background-color: black;
    height:100px
  }

  .iapl-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color:white;
    padding: 10px;
    padding-top: 14px;
  }

  .iapl-col1 a,  .iapl-col2 a,  .iapl-col3 a,  .iapl-col4 a, .iapl-col5 a {
    text-decoration: none;
    color:white
  }

  .iapl-col1 li,  .iapl-col2 li,  .iapl-col3 li,  .iapl-col4 li, .iapl-col5 li  {
    list-style-type: none;
  }

  @media screen and (max-width: 768px) {
    .footer-main-content {
     display: flex;
     justify-content: center;
     align-items: center;
    }

    .iapl-container {
      display: flex;
      color:white;
      padding: 10px;
    }

    .iapl-sub-col1,  .iapl-sub-col2, .iapl-sub-col3,  .iapl-sub-col4, .iapl-sub-col5 {
      margin: 5px;
      font-size: 12px;
    }

    .footer-links {
      font-size: 12px;
    }

    .copyright {
      font-size: 12px;
    }
  }