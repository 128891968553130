.project-body {
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
}

.project-body-sub{
    width: 50%;
}

.block-title {
    display: flex;
    width: 90%;
    height: 50px;
    background-color:rgb(21 153 135);
    align-items: center;
    font-weight: 500;
    font-size: 21px;
    color: white;
    margin-bottom: 10px;
    padding-left: 30px;
}

.gallery-item {
    display: flex;
    flex-direction: column;
}

.gallery-row {
    display: flex;
    margin-bottom: 20px;
}

.image-container {
    flex: 1;
    max-width: 300px;
    max-height: 300px;
    margin-right: 10px;
    overflow: hidden; /* Hide any overflow */
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Preserve the aspect ratio while covering the container */
}

.project-main-container {
    background-color: #2b5294;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), 
                -5px -5px 10px rgba(179, 214, 23, 0.5); */
    height: 70px;
    position: relative;
    color: white;
}

.project-main-container-h1 {
    padding-top: 5px;
    margin-left: 50px;
    font-size: 40px;
    position: relative;
    color: white;
}

@media screen and (max-width: 1068px) {
    .block-title {
        width: 85%;
        font-size: 18px;
    }
}

@media screen and (max-width: 850px) {
    .gallery-item {
        display: block;
        align-items: center;
        align-content: center;
        margin-left: 30px;
    }

    .gallery-row {
        display: block;
    }

    .project-body {
        margin-left: 25px;
        margin-right: 55px;
    }

}

@media screen and (max-width: 768px) {
    .project-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project-main-container-h1 {
        margin-left: 15px;
        font-size: 35px;
    }
    .block-title {
        width: 100%;
        font-size: 16px;
    }
    .project-body-sub{
        width: 100%;
    }
}

