.mh-video-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mh-what-unique {
  width: 500px;
  height: 300px;
}

.main-body {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .mh-what-unique {
    width: auto;
    height: auto;
  }
}

.wl-p-main-container {
  display: flex;
  background-color: white;
  justify-content: space-around;
  text-decoration: none;
}

.wl-p-main-container a {
  text-decoration: none;
}

.cont-body-1 {
  display: flex;
  flex-direction: row;
}

.services-level {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  padding: 5px;
  font-size: 30px;
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  .services-level {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    padding: 5px;
    font-size: 10px;
    font-weight: 500;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .services-level {
    font-size: 15px;
    /* 16px, same as the root font size */
  }
}

.mh-wl-p, .mep-de-wlp {
  position: relative;
  box-sizing: border-box;
  width: 32%;
  height: 250px;
  background-color: white;
  border-color: #272a2e;
  border: solid;
  border-width: 0.01px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-left: 3px;
  margin-right: 3px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .wl-p-main-container {
    display: block;
    position: relative;
    background-color: white;
    margin-left: 3px;
    margin-right: 3px;
  }
  .mh-wl-p, .mep-de-wlp {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    background-color: white;
    border-color: #272a2e;
    border: solid;
    border-width: 0.01px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mh-hm-b {
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.theme-button {
  display: inline-block;
  padding: 10px 20px;
  background-image: linear-gradient(to right, red, #333);
  color: white;
  text-decoration: none;
}

.theme-button:hover {
  background-image: linear-gradient(to right, #333, red);
}

.mh-logo {
  max-width: 80%;
  max-height: 80%;
  margin: -50px auto 0;
}

.ser-sub-div1 {
  width: 70%;
  align-items: center;
  text-align: center;
}

.sub-cont-body-1 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.Main-Comp-Body {
  width: fit-content;
}

.Body-Comp-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
}

.Body-Comp-Turnkey {
  box-sizing: border-box;
  margin-top: 0px;
  padding: 5px;
  display: flex;
  width: 100%;
  height: 400px;
  background-color: rgb(43 82 148);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.Body-Comp-Turnkey-Sub {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 30px;
}

.Body-Comp-Turnkey-Sub-xpa {
  font-size: 35px;
}

.Body-Comp-Turnkey-Sub-xpb {
  font-size: 25px;
}

.Body-Comp-Turnkey-Sub-xpc {
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.Body-Comp-Turnkey-Sub-xpca {
  display: flex;
  font-size: 18px;
  width: 120px;
  height: 35px;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-color: aqua;
  border-radius: 5px;
  cursor: pointer;
}

.Body-Comp-Buy-Homes {
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  display: flex;
  width: 100%;
  height: 400px;
  background-color: rgb(21 153 135);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.Body-Comp-Buy-Homes-Sub {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 30px;
}

.Body-Comp-Buy-Homes-Sub-xpa {
  font-size: 40px;
}

.Body-Comp-Buy-Homes-Sub-xpb {
  font-size: 25px;
}

.Body-Comp-Buy-Homes-Sub-xpc {
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.Body-Comp-Buy-Homes-Sub-xpca {
  display: flex;
  font-size: 18px;
  width: 120px;
  height: 35px;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  background-color: aqua;
  border-radius: 5px;
  cursor: pointer;
}

.Body-Comp-2-header {
  margin-top: 40px;
  font-size: 70px;
}

.Service-Type {
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: space-around;
}

@media (max-width: 1068px) {
  .Body-Comp-Buy-Homes {
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    width: 100%;
    height: 400px;
    background-color: rgb(21 153 135);
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  .Body-Comp-Buy-Homes-Sub {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 15px;
  }
  .Body-Comp-Buy-Homes-Sub-xpa {
    font-size: 40px;
  }
  .Body-Comp-Buy-Homes-Sub-xpb {
    font-size: 21px;
  }
  .Body-Comp-Buy-Homes-Sub-xpc {
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  .Body-Comp-Buy-Homes-Sub-xpca {
    display: flex;
    font-size: 18px;
    width: 120px;
    height: 35px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-color: aqua;
    border-radius: 5px;
    cursor: pointer;
  }
  .Body-Comp-2-header {
    margin-top: 40px;
    font-size: 70px;
  }
}

@media (max-width: 768px) {
  .Body-Comp-Turnkey-Sub-xpa {
    font-size: 25px;
  }
  .Body-Comp-Turnkey-Sub-xpb {
    font-size: 20px;
  }

  .Body-Comp-Buy-Homes-Sub-xpa {
    font-size: 25px;
  }
  .Body-Comp-Buy-Homes-Sub-xpb {
    font-size: 20px;
  }
}